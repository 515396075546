@import "Extentions";

.lastUpdatesReact {
  table {
    font-size: 12px;
    a {
      color: #111;
      &:hover {
        color: #056bf1;
        text-decoration: none;
      }
    }
  }
  .card-header {
    font-size: 14px;
    padding: 0 15px;
    font-weight: 700;
    height: 45px;
    line-height: 45px;
    background: 0 0;
    border: none;
    .card-more {
      visibility: hidden;
      float: right;
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      text-align: center;
      &:hover {
        background-color: #e7e7e7;
        text-decoration: none;
      }
      > i {
        line-height: 24px;
        display: block;
      }
    }
  }
  &:hover .card-more {
    visibility: visible;
  }
  .novel-name {
    a {
      font-family: 'Roboto Condensed';
      font-weight: 700;
    }
  }
  .genre {
    white-space: nowrap;
    padding-left: 15px !important;
  }
  .btn.btn-secondary.btn-sm {
    padding: .2rem .5rem;
    font-size: 0.7rem;
    &.active {
      color: #fff200;
      background-color: #545b62;
      border-color: #4e555b;
    }
  }
  #rap-overlay {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    z-index: 2;
    cursor: pointer;
  }

  .rap-manager,
  .rap-target-container {
    display: inline-block;
    position: relative;
  }

  .rap-left {
    left: -20px !important;
  }
  .rap-bottom {
    top: 15px !important;
  }
  .rap-top {
    top: -15px !important;
  }
  .rap-right {
    left: 20px !important;
  }

  .ar {
    transform: rotate(-180deg);
  }
  .at {
    transform: rotate(90deg);
  }
  .ab {
    transform: rotate(-90deg);
  }

  .rap-popover-content {
    font-family: 'Roboto Condensed';
    background: white;
    box-shadow: 0 0 4px 0 rgba(68,68,68,1);
    border-radius: 4px;
    border: 0;
  }
  .pad {
    padding: 10px;
    width: 300px;
  }

  .iconText {
    display: flex;
    align-items: center;
  }
}


